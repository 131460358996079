@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --iabcolour: #FF0000;
  --iabcolour1: #F19B3C;
  --iabcolour2: #ffffff;
}

@media (prefers-color-scheme: dark) {
  :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --iabcolour: #FF0000;
  --iabcolour1: #F19B3C;
  --iabcolour2: #ffffff;
  }
}

body {
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
  background-color: var(--background-color);
  color: var(--primary-color);
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

/* Additional styles to demonstrate usage [var(--primary-color)]*/
button.primary {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
}

button.secondary {
  background-color: white;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
}

button.long-primary {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
}

button.long-secondary {
  background-color: white;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
}

a {
  color: var(--primary-color);
}

a:hover {
  color: var(--secondary-color);
}

/* Input styles */
input.primary, select.primary {
  display: block;
  width: 100%;
  padding: 6px 12px;
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  color: black;
/*  background-color: var(--background-color);
  color: var(--secondary-color);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);*/
}

input.primary::placeholder, select.primary::placeholder {
  color: black;
}

input.primary:focus, select.primary:focus {
  color: black;
/*  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);*/
}

.bg-iabcolour {
  background-color: var(--primary-color);
}
